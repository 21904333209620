html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.principal {
    background-color: #07090e;
    color: white;
    min-height: 91.2vh;
}

.nosotros {
    background-color: #ffffff;
    color: #07090E;
    min-height: 93.5vh;
    display: flex;
    flex-direction: column;
}

.productos {
    background-color: #ffffff;
    color: #07090E;
    min-height: 92.5vh;
    position: relative;
}

.principal> :first-child {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 2%;
}

.principal div h1 {
    max-width: 30%;
}

.principal div img {
    width: 40%;
}

.prinProd {
    display: flex;
}

.prinProd> :first-child {
    display: flex;
    max-width: 50%;
    height: 100%;
    flex-direction: column;
}

.prinProd> :first-child p {
    width: 70%;
}

.prinProd> :first-child a {
    padding: 5%;
    background-color: #dae0e0;
    color: black;
    height: 50%;
    width: 30%;
    text-align: center;
    margin-left: 70%;
    font-weight: 700;
}

.prinProd> :first-child a img {
    width: 10%;
    padding-left: 2px;
}

.prinProd> :last-child {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-around;
    background-color: #232324;
}

.prinProd> :last-child span {
    margin-bottom: 13%;
    font-size: x-large;
}

.prinProd> :last-child a {
    background-image: url(./svg/nosotros.jpg);
    background-size: cover;
    width: 70%;
    height: 72%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.prinProd> :last-child a:hover {
    opacity: 0.5;
}

.prinProd> :last-child a img {
    width: 20%;
}

.navbar-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    flex-basis: 52%;
}

.navbar-nav a {
    margin: 0 2%;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    color: #dae0e6;
}

.navbar-nav img {
    width: 35px;
    padding-top: 0px;
}

.navbar-nav a:hover {
    color: #a03c53;
}

#responsive-navbar-nav button {
    background-color: white;
    color: #07090e;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
}

#responsive-navbar-nav button :hover {
    background-color: #a03c53;
}

.active {
    color: #a03c53 !important;
}

.display-linebreak {
    white-space: pre-line;
}

.title-padding {
    padding: 10%;
}


/* ------------------------------------------------------------------------------
  Footer
  ------------------------------------------------------------------------------*/

.footer {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #343a40;
    width: 100%;
    color: #DAE0E6;
    height: calc(100% - 67vh);
    justify-content: space-around;
    align-items: center;
    padding-bottom: 0.5em;
}

.footer section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.content-footer {
    margin-top: 1em;
}

.information {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 100%;
}

.information div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.information a {
    color: white;
}

.social {
    width: 33%;
    height: 100%;
}

.social>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.social>div>div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.social a {
    width: 20%;
}

.social span {
    margin-bottom: 2vh;
}

.imagesf {
    width: 100%;
    color: #DAE0E6;
    border-radius: 1vh;
    background-color: #6F7B88;
}

.imagesf:hover {
    background-color: #0061a9;
}

.imagesl {
    width: 100%;
    color: #DAE0E6;
    border-radius: 1vh;
    background-color: #6F7B88;
}

.imagesf:hover {
    background-color: #4267b2;
}

.imagesl:hover {
    background-color: #0077B5;
}

.EFQ {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 33%;
    height: 100%;
}

.EFQ img {
    width: 20%;
    align-self: center;
}

.imgRow {
    width: 5vh;
    transition: 1s;
    display: none;
}

.footer-bottom-section {
    width: 100%;
}

.footer-bottom-section>div:first-child {
    margin-right: auto;
    font-size: 0.7em;
}

.footer-bottom-wrapper {
    font-size: 0.7em;
    color: #A03C53;
}

.footer-address {
    display: inline;
    font-style: normal
}


/* ------------------------------------------------------------------------------
  NOSOTROS
  ------------------------------------------------------------------------------*/

.nosotros>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.nosotros>div {
    height: 33%;
}

.efq-article-nosotros {
    width: 50%;
    height: 70%;
    padding: 5%;
    padding-top: 1vh;
    margin: 10px;
}

.efq-article-mision {
    width: 60%;
    background: #232324;
    margin: 10px 1%;
    padding: 5%;
    margin-right: 4%;
}

.efq-article-vision {
    width: 60%;
    background: #232324;
    padding: 5%;
    margin-bottom: -1%;
}

.efq-article-title-nosotros {
    margin: 10px 1%;
    padding-left: 5%;
}

.efq-article-title-mision {
    width: 60%;
    margin: 10px 1%;
    padding-left: 5%;
}

.efq-article-title-vision {
    width: 60%;
    margin: 10px 1%;
    padding-left: 5%;
}


/* ------------------------------------------------------------------------------
  PRODUCTOS
  ------------------------------------------------------------------------------*/

.efq-title-productos {
    margin: 10px 1%;
    padding-left: 5%;
}

.productos-body {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 100%;
    overflow: hidden;
    font-family: sans-serif;
    position: relative;
    min-width: 410px;
}

.move-icon {
    cursor: pointer;
}

.move-icon {
    z-index: 2;
    top: 35%;
    font-size: 1.2rem;
    position: absolute;
    color: #DAE0E6;
    font-weight: 700;
    border-radius: 50%;
    width: 1.8em;
    height: 1.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background: #07090E;
}

.move-prev {
    left: 2%;
}

.move-back {
    right: 2%;
}

.card-group {
    display: flex;
    position: absolute;
    left: 0;
    top: 10%;
    transition: order 0.3s easeout;
}

.card {
    width: 220px;
    height: 300px;
    border-radius: 20px;
    padding: 1.5em 2em;
    margin: auto 0.5em;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    background: #07090E;
}

.card img {
    width: 100%;
    height: 75%;
    display: flex;
    position: absolute;
    top: 20%;
    left: 0;
}

.card button {
    background: #07090e;
    border: none;
    color: #dae0e0;
    font-weight: bold;
    padding: 0.5em 1em 0.5em;
    width: 100%;
    float: right;
    font-family: sans-serif;
    cursor: pointer;
    outline: none;
    display: flex;
    position: absolute;
    left: 0;
    top: 80%;
    opacity: 90%;
}

.card h4 {
    font-size: 1em;
}

.modal-body img {
    width: 100%;
}


/* ------------------------------------------------------------------------------
  NUESTRAS SOL
  ------------------------------------------------------------------------------*/

.nuestras {
    background-color: #ffffff;
    color: #07090E;
    min-height: 93.5vh;
    display: flex;
    flex-direction: row;
}

.nuestras>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
    padding-left: 1vh;
    background-color: #DAE0E6;
}

.nuestras>div:nth-child(2) {
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
}


/* ------------------------------------------------------------------------------
  Headings
  ------------------------------------------------------------------------------*/

h1 {
    font-size: 3em;
}

h2 {
    color: #A03C53;
    font-size: 2em;
}

h3 {
    text-transform: uppercase;
    font-size: 2.5em;
}

.light-text {
    color: #DAE0E6;
}

.efq-container-footer {
    float: right;
    width: 73%;
    background: #6F7B88;
    padding: 1vh;
}

.cards {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(26vh, max-content));
    grid-gap: 3vh;
    align-items: stretch;
    max-height: calc(100% - 33vh );
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.MuiPaper-rounded {
    min-height: 25vh;
    width: 28vh;
    margin: 0 2vh;
    min-width: 28vh;
}

.modal-image-iesc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1vh;
}

.modal-image-iesc img{
    width: 50%;
}
.modal-image-iesc p{
    width: 50%;
}
/* ------------------------------------------------------------------------------
  Clientes
  ------------------------------------------------------------------------------*/

.clientes {
    background: #07090E;
    min-height: 92.3vh;
    position: relative;
    padding: 3vh 0 0 0;
}

.efq-clientes {
    overflow: hidden;
    position: relative;
    height: 100vh;
}

.efq-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.efq-page__half {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100vh;
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
}

.efq-page__half--left {
    left: 0;
    -webkit-transform: translate3d(-32.4vh, 100%, 0);
    transform: translate3d(-32.4vh, 100%, 0);
}

.efq-page__half--right {
    left: 50%;
    -webkit-transform: translate3d(32.4vh, -100%, 0);
    transform: translate3d(32.4vh, -100%, 0);
}

.efq-page.active .efq-page__half {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.efq-page__skewed {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 140%;
    height: 100%;
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg);
    background: #07090E;
}

.efq-page__half--left .efq-page__skewed {
    left: -40%;
}

.efq-page__half--right .efq-page__skewed {
    right: -40%;
}

.efq-page__content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column wrap;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 30%;
    color: #6F7B88;
    -webkit-transform: skewX(18deg);
    transform: skewX(18deg);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    background-size: cover;
}

.efq-page__half--left .efq-page__content {
    padding-left: 30%;
    padding-right: 30%;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.efq-page__half--right .efq-page__content {
    padding-left: 30%;
    padding-right: 30%;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
}

.efq-page.inactive .efq-page__content {
    opacity: 0.5;
    -webkit-transform: skewX(18deg) scale(0.95);
    transform: skewX(18deg) scale(0.95);
}

.efq-page__heading {
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 25px;
    text-align: center;
}

.efq-page__description {
    font-size: 18px;
    text-align: center;
}

.efq-page-1 .efq-page__half--left .efq-page__content {
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/mousover-img-1.jpg");
}

.efq-page__half--right .efq-page__content {
    background: #07090E;
}

.arrow-left {
    width: 5vh;
    top: 50%;
    position: fixed;
    transform: rotate(270deg);
    z-index: 1 !important;
    cursor: pointer;
    background-color: #07090e;
    opacity: 20%;
}

.arrow-left:hover {
    background-color: #a03c53;
}

.arrow-right {
    width: 5vh;
    top: 50%;
    right: 0;
    position: fixed;
    transform: rotate(90deg);
    z-index: 1 !important;
    cursor: pointer;
    background-color: #07090e;
    opacity: 20%;
}

.arrow-right:hover {
    background-color: #a03c53;
}

.cl-desc {
    width: auto;
    margin-right: none;
    padding: 0%;
}

.cl-cards {
    max-height: calc(100% - 40vh);
}


/* ------------------------------------------------------------------------------
  Contactanos
  ------------------------------------------------------------------------------*/

.contactanos {
    background-color: #ffffff;
    color: #07090E;
    min-height: 92.5vh;
    position: relative;
}

.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }
    80% {
        transform: translateY(-10px) rotate(-45deg);
    }
    100% {
        transform: translateY(0) rotate(-45deg);
    }
}

.infoContact {
    display: flex;
    max-height: calc(100% - 30vh);
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
}

.direccion {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 0 0 3vh;
}

.contacto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    padding: 0 10vh;
}

.list {
    width: 100%;
    background-color: #fff;
}

.inline {
    display: inline;
}


/* ------------------------------------------------------------------------------
  Responsive
  ------------------------------------------------------------------------------*/

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .productos{
        min-height: 89.5vh;
    }
    .contactanos{
        min-height: 89.5vh;
    }
    .navbar-nav a {
        padding: 6% 0;
        width: 100%;
        text-align: center;
    }
    #responsive-navbar-nav img {
        width: 50px;
        padding-top: 0px;
    }
    #responsive-navbar-nav a {
        width: 100%;
        padding-top: 0px;
    }
    #responsive-navbar-nav button {
        width: 100%;
    }
    .principal div img {
        display: none;
    }
    .principal> :first-child {
        display: unset;
        align-items: unset;
        justify-content: unset;
        margin-bottom: 2%;
    }
    .principal div h1 {
        max-width: unset;
        text-align: center;
    }
    .prinProd> :first-child {
        max-width: unset;
        height: unset;
        margin-bottom: 5%;
    }
    .prinProd {
        flex-direction: column;
        align-items: center;
    }
    .prinProd> :first-child a {
        padding: 4% 0;
        background-color: #dae0e0;
        color: black;
        height: 50%;
        width: unset;
        text-align: center;
        margin-left: unset;
        font-weight: 700;
    }
    .prinProd> :first-child p {
        width: unset;
    }
    .prinProd> :last-child {
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 90%
    }
    .prinProd> :last-child span {
        font-size: x-large;
    }
    .prinProd> :last-child a {
        width: 100%;
        height: 27vh;
        padding-top: unset;
        padding: 10%;
        margin: 0 0 9%;
    }
    .prinProd> :last-child a img {
        display: unset;
    }
    .nosotros article {
        width: unset;
    }
    .cards {
        max-height: calc(100% - 12vh);
        grid-template-columns: repeat(auto-fill, minmax(100%, max-content));
    }
    .MuiPaper-rounded {
        width: 100%;
        margin: 0 0;
    }
    .footer {
        position: fixed;
        height: calc(100% - 43vh);
        bottom: -51.5vh;
        transition: 1s;
        width: 100%;
    }
    .footer section {
        flex-direction: column;
    }
    .content-footer {
        flex-direction: column;
        width: 100%;
    }
    .imgRow {
        display: unset;
        margin-left: 84%;
    }
    .open {
        bottom: 0;
        height: calc(100% - 30vh);
    }
    .reversed {
        transform: rotate(180deg);
    }
    .information {
        width: 100%;
        margin-bottom: 2vh;
    }
    .social {
        width: 100%;
        margin-bottom: 2vh;
    }
    .EFQ {
        width: 100%;
        margin-bottom: 1vh;
    }
    .footer-bottom-section {
        width: 100%;
        margin-top: 0em;
        flex-direction: column;
        font-size: 10px;
    }
    .nuestras>div:nth-child(1) {
        width: 15%;
    }
    .nuestras>div:nth-child(2) {
        padding: 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 45%;
    }
    .infoContact {
        flex-direction: column;
        max-height: calc(100% - 12vh);
    }
    .direccion {
        margin: unset;
        width: 100%;
    }
    .contacto {
        width: 100%;
        padding: unset;
    }
    .cl-cards {
        max-height: calc(100% - 21vh);
    }
    .modal-image-iesc{
        flex-direction: column;
    }
    .modal-image-iesc img{
        width:  100%;
    }
    .modal-image-iesc p{
        width: 100%;
    }
}

@media screen and (max-width: 320px) and (max-height: 568px) {
    .footer {
        bottom: -51.5vh;
    }
    .open {
        bottom: 0;
        height: calc(100% - 30vh);
    }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
    .contacto {
        padding: unset;
        font-size: xx-large;
    }
}

@media screen and (min-height: 1366px) {
    .nosotros article {
        font-size: xx-large;
    }
    .principal p {
        font-size: xx-large;
    }
    .principal span {
        font-size: x-large;
    }
    .principal a {
        font-size: x-large;
    }
    .contacto {
        padding: unset;
        font-size: xx-large;
    }
}
